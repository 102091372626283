export default [
    {
        label: 'Ativo',
        value: 'active',
        class: 'text-positive'
    },
    {
        label: 'Manutenção',
        value: 'maintenance',
        class: 'text-warning'
    },
    {
        label: 'Suspenso',
        value: 'suspended',
        class: 'text-orange'
    },
    {
        label: 'Homolog',
        value: 'homolog',
        class: 'text-purple'
    },
    {
        label: 'Cancelado',
        value: 'cancel',
        class: 'text-negative'
    }
]
