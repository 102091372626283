<template>
  <div class="cli-rating-container" :class="'size-' + size">
    <div
        v-for="emoji in emojis"
        :key="emoji.level"
        class="emoji-item"
        :class="{ 'selected': selectedRating === emoji.level }"
        @click="setRating(emoji.level)"
    >
      <img :src="emoji.src" :alt="emoji.label"/>
      <span>{{ emoji.label }}</span>
    </div>
  </div>
</template>

<script>
import ClienteMixin from "components/clientes/components/cliente/include/ClienteMixin"
import rate1 from 'components/clientes/assets/images/rate1.png'
import rate2 from 'components/clientes/assets/images/rate2.png'
import rate3 from 'components/clientes/assets/images/rate3.png'
import rate4 from 'components/clientes/assets/images/rate4.png'
import rate5 from 'components/clientes/assets/images/rate5.png'
import {updateCliente} from "@/domain/gerencia/clientes/services"

export default {
  name: 'SatisfacaoRating',
  props: {
    cliente: {required: true},
    size: {required: false}
  },
  mixins: [],
  data() {
    return {
      selectedRating: null,
      emojis: [
        {level: 1, src: rate1, label: 'Muito insatisfeito'},
        {level: 2, src: rate2, label: 'Insatisfeito'},
        {level: 3, src: rate3, label: 'Neutro'},
        {level: 4, src: rate4, label: 'Satisfeito'},
        {level: 5, src: rate5, label: 'Muito satisfeito'}
      ],
    };
  },
  mounted() {
    this.selectedRating = this.cliente.indiceSatisfacao
  },
  methods: {
    setRating(level) {
      this.selectedRating = level
      this.$emit('rating-selected', level)
      this.confirmUpdate(level)
    },
    confirmUpdate(level) {
      const old = this.cliente.gerente
      this.loading = true
      updateCliente(this.cliente.id, {
        id: this.cliente.id,
        indiceSatisfacao: level,
        notify: 'indiceSatisfacao'
      }, 'PATCH')
          .then(response => {
            this.loading = false
            this.$emit('update', level)
          })
          .catch(error => {
            this.loading = false
            this.cliente.gerente = old
            this.alertApiError(error)
          })
      this.cliente.indiceSatisfacao = level
    },
  },
};
</script>
