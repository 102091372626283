<template>
  <div class="scliente-details">
    <satisfacao-rating :cliente="cliente" />
    <cliente-right-tab title="Informações">
      <div class="detail sperson">
        <div class="lbl">Gerente</div>
        <div class="value">
          <gerente-edit />
        </div>
      </div>
      <div class="detail">
        <div class="lbl">Domínio</div>
        <div class="value">
          <a :href="'https://' + cliente.dominio" target="_blank">{{cliente.dominio}}</a>
        </div>
      </div>
      <div class="detail">
        <div class="lbl">Plano</div>
        <div class="value">
          {{cliente.plano.nome}}
        </div>
      </div>
      <div class="detail">
        <div class="lbl">Infra</div>
        <div class="value">
          {{cliente.servidor.nome}}
        </div>
      </div>
      <div class="detail">
        <div class="lbl">Leilões</div>
        <div class="value">
          {{cliente.statLeiloes|number}}
        </div>
      </div>
      <div class="detail">
        <div class="lbl">Bens</div>
        <div class="value">
          {{cliente.statBens|number}}
        </div>
      </div>
      <div class="detail">
        <div class="lbl">Base Cadastral</div>
        <div class="value">
          {{cliente.statClientes|number}}
        </div>
      </div>
      <div class="detail">
        <div class="lbl">Vendas</div>
        <div class="value">
          {{cliente.statVendas|moeda}}
        </div>
      </div>
      <div class="detail">
        <div class="lbl">Status</div>
        <div class="value">
          <status-cliente :cliente="cliente" />
        </div>
      </div>
    </cliente-right-tab>
    <cliente-right-tab title="Financeiro">
      <div class="detail">
        <div class="lbl">Mensalidade</div>
        <div class="value">
          {{cliente.mensalidade|moeda}}
        </div>
      </div>
      <div class="detail">
        <div class="lbl">Periodicidade</div>
        <div class="value">
          <div v-if="cliente.mensalidadePeriodicidade === 1">Mensal</div>
          <div v-if="cliente.mensalidadePeriodicidade === 2">Bimestral</div>
          <div v-if="cliente.mensalidadePeriodicidade === 3">Trimestral</div>
          <div v-if="cliente.mensalidadePeriodicidade === 6">Semestral</div>
          <div v-if="cliente.mensalidadePeriodicidade === 12">Anual</div>
        </div>
      </div>
      <div class="detail">
        <div class="lbl">Dia de Vencimento</div>
        <div class="value">
          {{cliente.mensalidadeDiaVencimento}}
        </div>
      </div>
      <div class="detail">
        <div class="lbl">Próximo Vencimento</div>
        <div class="value">
          <div v-if="cliente.mensalidadeProximoVencimento">{{cliente.mensalidadeProximoVencimento|formatDate('dd/MM/yyyy')}}</div>
          <div v-else>Nunca</div>
        </div>
      </div>
      <div class="detail">
        <div class="lbl">Último Pagamento</div>
        <div class="value">
          <div v-if="cliente.mensalidadeUltimoPagamento">{{cliente.mensalidadeUltimoPagamento|formatDate('dd/MM/yyyy')}}</div>
          <div v-else>Nunca</div>
        </div>
      </div>
      <div class="detail">
        <div class="lbl">Ticket In</div>
        <div class="value">
          {{cliente.ticketIn|moeda}}
        </div>
      </div>
      <div class="detail">
        <div class="lbl">Ticket Out</div>
        <div class="value">
          {{cliente.ticketOut|moeda}}
        </div>
      </div>
    </cliente-right-tab>
    <div class="text-grey font-11 m-t">
      Criado {{ cliente.createdAt|formatDate }}
      <div v-if="cliente.dateModified" class="m-t-xxs">Atualizado {{ cliente.dateModified.date|formatDateDistance }}</div>
    </div>
  </div>
</template>

<script>
import {UTooltip} from "uloc-vue"
import ClienteRightTab from "components/clientes/components/cliente/include/RightTab"
import ClienteMixin from "components/clientes/components/cliente/include/ClienteMixin"
import SatisfacaoRating from "components/clientes/components/cliente/include/SatisfacaoRating"
import GerenteEdit from "components/clientes/components/cliente/include/GerenteEdit"
import StatusCliente from "components/clientes/components/cliente/include/StatusCliente"

export default {
  name: "ClienteDetalhes",
  components: {
    StatusCliente,
    GerenteEdit,
    SatisfacaoRating,
    ClienteRightTab,
    //UTooltip,
  },
  mixins: [ClienteMixin],
}
</script>
