<script>
import {find, remove} from "@/domain/gerencia/clientes/services"
import {UFakeLoader, UTooltip} from "uloc-vue"
import ClienteMenuRight from "components/clientes/components/cliente/include/ClienteMenuRight"
import ClienteDetalhes from "components/clientes/components/cliente/include/ClienteDetalhes"
import ErpSField from "@/plugins/uloc-erp/components/form/SimpleField"
import ERow from "components/layout/components/Row"
import ECol from "components/layout/components/Col"
import clienteWindow from "components/clientes/components/cliente/window/cliente"

export default {
  name: 'Cliente',
  provide: function () {
    return {
      clienteContainer: this
    }
  },
  inject: ['container'],
  mixins: [],
  components: {
    ECol,
    ERow,
    ErpSField,
    ClienteDetalhes,
    ClienteMenuRight,
    UFakeLoader,
    // UTooltip,
  },
  data () {
    return {
      routeName: ['clientes.cliente'],
      menuActive: {
        name: 'opened',
        label: 'Em aberto',
      },
      loading: true,
      cliente: null
    }
  },
  mounted() {
    document.body.classList.add('scliente-body')
    this.$on('update', () => {
          find(this.$route.params.id)
              .then(response => {
                this.cliente = response.data
              })
    })
    this.load()
  },
  beforeDestroy() {
    document.body.classList.remove('scliente-body')
  },
  computed: {
  },
  watch: {
    '$route.params.id' (v) {
      this.$nextTick(() => {
        this.load()
      })
    }
  },
  methods: {
    load() {
      this.loading = true
      find(this.$route.params.id)
          .then(response => {
            this.cliente = response.data
            setTimeout(() => {
              this.$options.meta.title = `#${this.cliente.id} ${this.cliente.pessoa.name} - Clientes`
            }, 2000)
            this.loading = false
          })
          .catch(error => {
            this.alertApiError(error)
          })
    },
    clienteWindow,
    edit () {
      this.clienteWindow(this.cliente.id)
    },
    remove () {
      this.confirmarExclusao('Tem certeza que deseja excluir este cliente? Todos os dados serão perdidos.', 'excluir cliente-' + this.cliente.id)
          .then(() => {
            this.$uloc.loading.show()
            remove(this.cliente.id)
                .then(response => {
                  this.dg('Cliente removido com sucesso')
                  this.$router.push({name: 'clientes.lista'})
                  this.$uloc.loading.hide()
                })
                .catch(error => {
                  this.alertApiError(error)
                  this.$uloc.loading.hide()
                })
          })
          .catch(() => {

          })
    }
  },
  meta: {
    title: 'Cliente',
    name: 'Cliente'
  }
}
</script>

<template>
  <div v-if="loading" style="padding: 20px">
    <div class="full-width flex justify-between">
      <div>
        <u-fake-loader width="200px" />
        <u-fake-loader width="100px" height="60px" />
        <br>
        <br>
        <br>
        <br>
        <u-fake-loader width="200px" />
        <u-fake-loader width="200px" />
      </div>
      <div class="text-right">
        <u-fake-loader width="200px" /><u-fake-loader />
        <u-fake-loader />
        <br>
        <br>
        <u-fake-loader />
        <u-fake-loader />
      </div>
    </div>
    <br>
    <br>
    <br>
    <u-fake-loader width="50%" />
  </div>
  <div v-else-if="routeName.includes($route.name)" class="scliente-page">
    <div class="cliente-contanier">
      <div class="flex items-center nav-with-btn cliente-bar-nav">
        <u-btn @click="$router.go(-1)" class="nab-btn" icon="chevron-left" dense icon-type="fa" label="Voltar" no-caps flat color="blue-grey" />
        <div class="nav-container">
          <ul class="snav">
            <li><a @click="$router.push({name: 'suporte.dashboard'})">Clientes</a></li>
            <li class="disable cursor-pointer td-limit l-200">{{ cliente.pessoa.name }}</li>
          </ul>
        </div>
      </div>
      <div class="cliente-content m-t-lg">
        <e-row mr>
          <e-col>
            <erp-s-field label="Nome do Cliente">
              {{ cliente.pessoa.name }}
            </erp-s-field>
          </e-col>
          <e-col>
            <erp-s-field label="Documento">
              {{ cliente.pessoa.document|formataCpfCnpj2 }}
            </erp-s-field>
          </e-col>
          <e-col>
            <erp-s-field label="Tipo do Cliente">
              {{ cliente.pessoa.type === 1 ? 'Pessoa Física' : 'Pessoa Jurídica' }}
            </erp-s-field>
          </e-col>
        </e-row>
        <e-row class="m-t" mr>
          <e-col>
            <erp-s-field label="E-mail">
              <div v-if="cliente.pessoa.emails.length">{{ cliente.pessoa.emails.map(e => e.email).join(', ') }}</div>
            </erp-s-field>
          </e-col>
          <e-col>
            <erp-s-field label="Telefone">
              <div v-if="cliente.pessoa.phoneNumbers.length">{{ cliente.pessoa.phoneNumbers.map(e => e.phoneNumber).join(', ') }}</div>
            </erp-s-field>
          </e-col>
        </e-row>
        <e-row class="m-t" mr>
          <e-col>
            <erp-s-field label="Observações internas">
              <div class="m-t">
               <div v-if="cliente.observacao">{{cliente.observacao}}</div>
               <div v-else>Nada informado</div>
              </div>
            </erp-s-field>
          </e-col>
        </e-row>
      </div>
    </div>
    <div class="cliente-bar-right">
      <div class="cliente-menu-icons">
        <cliente-menu-right />
      </div>
      <div class="line2-bar">
        <cliente-detalhes />
      </div>
<!--      <div style="height: 2000px"></div>-->
    </div>
  </div>
  <router-view v-else />
</template>
