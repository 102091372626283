<template>
  <div class="assigned-input hoverable">
    <person-select v-if="mounted" class="es-simple" v-model="gerente" />
    <div v-if="gerente" @click="unassign" class="unassign hidden-nohover"><a>Desatribuir</a></div>
    <div v-else class="unassign hidden-nohover"><a @click="assign">Atribuir a mim</a></div>
  </div>
</template>

<script>
import PersonSelect from "components/suporte/components/projetos/include/PersonSelect"
import {updateCliente} from "@/domain/gerencia/clientes/services"
import ClienteMixin from "components/clientes/components/cliente/include/ClienteMixin"

export default {
  name: "ResponsavelEdit",
  data () {
    return {
      gerente: null,
      bind: false,
      mounted: true
    }
  },
  mounted() {
    this.gerente = this.cliente.gerente
    this.$nextTick(() => {
      this.bind = true
    })
  },
  watch: {
    gerente (v) {
      this.bind && this.change(v)
    }
  },
  methods: {
    change (v) {
      const old = this.cliente.gerente
      this.$uloc.dialog({
        title: 'Confirmar alteração de Responsável',
        message: 'Alterar responsável da tarefa para ' + v.name + '?',
        ok: 'Alterar responsável',
        cancel: 'Cancelar',
        color: 'black',
        noCaps: true
      }).then(() => {
        this.confirmUpdate(v)
      })
          .catch(() => {
            this.mounted = false
            this.bind = false
            this.gerente = old
            this.$nextTick(() => {
              this.mounted = true
              this.bind = true
            })
          })
    },
    confirmUpdate (person) {
      const old = this.cliente.gerente
      this.loading = true
      updateCliente(this.cliente.id, {
        id: this.cliente.id,
        gerente: person ? person.id : null,
        notify: 'gerenteCliente'
      }, 'PATCH')
          .then(response => {
            this.loading = false
            this.$emit('update', person)
          })
          .catch(error => {
            this.loading = false
            this.cliente.gerente = old
            this.alertApiError(error)
          })
      if (!person) {
        this.mounted = false
        this.bind = false
        this.$nextTick(() => {
          this.gerente = null
          this.$nextTick(() => {
            this.mounted = true
          })
        })
      }
      this.cliente.gerente = person
    },
    unassign () {
      this.$uloc.dialog({
        title: 'Confirmar alteração de Responsável',
        message: 'Remover responsável para esta tarefa?',
        ok: 'Alterar responsável',
        cancel: 'Cancelar',
        color: 'black',
        noCaps: true
      }).then(() => {
        this.confirmUpdate(null)
      })
          .catch(() => {})
    },
    assign () {
      this.$uloc.dialog({
        title: 'Confirmar alteração de Responsável',
        message: 'Confirmar atribuição desta tarefa à você?',
        ok: 'Atrubuir a mim',
        cancel: 'Cancelar',
        color: 'black',
        noCaps: true
      }).then(() => {
        const person = {
          id: this.$uloc.auth.session.user.person,
          photo: this.$uloc.auth.session.user.photo,
          name: this.$uloc.auth.session.user.name,
        }
        this.mounted = false
        this.bind = false
        this.$nextTick(() => {
          this.gerente = person
          this.$nextTick(() => {
            this.mounted = true
            this.bind = true
          })
        })
        this.confirmUpdate(person)
      })
          .catch(() => {})
    }
  },
  components: {PersonSelect},
  mixins: [ClienteMixin]
}
</script>
